

  import {Component, Prop, Vue} from "vue-property-decorator";

  @Component({name:'ProjectPublishButton'})
  export default class ProjectPublishButton extends Vue {

    @Prop({required:true})
    scope:any;

  }


